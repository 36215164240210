import { FlickityRefObject } from '../types/FlickityRefObject'
import { useEffect } from 'react'

export const useFlickityVisibilityFix = (ref: FlickityRefObject) => {
  useEffect(() => {
    setTimeout(() => {
      if (ref.current && ref.current.flkty) {
        ref.current.flkty.select(1)
      }

      setTimeout(() => {
        if (ref.current && ref.current.flkty) {
          ref.current.flkty.select(0)
        }
      }, 200)
    }, 200)
  }, [ref])
}